import React from "react";
import SectionTitle from "../elements/SectionTitle";

const HomeAboutUs = () => {
  return (
    <div className="container mx-auto text-center lg:text-left xl:px-32 pb-24">
      <SectionTitle title="About Us" isBright={false} />

      <div className="grid items-center lg:grid-cols-2 max-w-[1080px] mx-auto">
        <div className="mb-12 lg:mb-0">
          <div className="relative z-[1] block rounded-3xl bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[20px] md:px-12 lg:-mr-14">
            <h5 className="mb-4 text-2xl font-medium text-primary-900">Empowering Our Customers’ Success with Passionate Expertise</h5>

            <p className="text-primary-100 font-roboto">
              At MonNutra, we are dedicated to empowering the success of health product manufacturers. With our professional and passionate team, we
              offer comprehensive services in ingredient procurement, quality assurance, and custom blending. Our mission is to streamline the supply
              chain, providing copackers in the industry with the ingredients they need and the logistical support they require. We are committed to
              ensuring top-quality products and exceptional customer service, driving the growth and satisfaction of our valued clients.
            </p>

            <br />

            <button
              type="button"
              className="inline-block rounded-full bg-primary-900 px-6 pb-3 pt-3.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out  hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-900 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
              onClick={() => (window.location.href = "/about")}
            >
              Learn more about us
            </button>
          </div>
        </div>

        <div className="">
          <img
            src="images/ingredients-measured.jpg"
            className="w-full rounded-lg shadow-lg dark:shadow-black/20"
            alt="Empowering Our Customers’ Success with Passionate Expertise"
          />
        </div>
      </div>
    </div>
  );
};

export default HomeAboutUs;
