import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/Home";

import ContactPage from "./pages/ContactPage";
import AboutPage from "./pages/AboutPage";
import ServicesPage from "./pages/ServicesPage";
import NotFoundPage from "./pages/NotFoundPage";

const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

const App = () => {
  useEffect(() => {
    const imageUrls = [
      "public/images/logo.png",
      "public/images/Ava.jpg",
      "public/images/coffee-process-agriculture.jpg",
      "public/images/curry-spice-wall.jpg",
      "public/images/featured-1.jpg",
      "public/images/featured-2.jpg",
      "public/images/featured-3.jpg",
      "public/images/home-2.jpg",
      "public/images/home.jpg",
      "public/images/ingredients-measured.jpg",
      "public/images/Jackson.jpg",
      "public/images/line-piles-colorful-spices.jpg",
      "public/images/line-piles-green-spices.jpg",
      "public/images/line-piles-spices.jpg",
      "public/images/Olivia.jpg",
      "public/images/outdoor-tree-sunlight.jpg",
      "public/images/outdoor-tree-fruit-sunlight.jpg",
      "public/images/services-1.jpg",
      "public/images/services-2.jpg",
      "public/images/services-3.jpg",
      "public/images/services-shipping.jpg",
      "public/images/outdoor-tree-fruit-sunlight.jpg",
      "public/images/icon_badge.png",
      "public/images/icon_handshake.png",
      "public/images/icon_target.png",
      "public/images/vibrant-burst-makeup-powder-dark-background.png",
      "public/images/wordcloud.svg",
    ];

    preloadImages(imageUrls);
  }, []);

  return (
    <Router>
      <AnimatePresence>
        <motion.div initial={{ opacity: 0, x: 0 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: 0 }} transition={{ duration: 0.5 }}>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/contact" element={<ContactPage />} />

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </motion.div>
      </AnimatePresence>
    </Router>
  );
};

export default App;
