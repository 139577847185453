// api.js
export async function sendContactForm(name, email, phone, message) {
  // Define the API endpoint and headers
  const url = "https://api.brevo.com/v3/smtp/email";
  const headers = {
    accept: "application/json",
    "api-key": "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",
    "content-type": "application/json",
  };

  // Define the request body
  const body = {
    sender: { name, email },
    to: [{ email: "monnutra@gmail.com", name: "MonNutra.com" }],
    subject: "MonNutra.com - Contact form filled!",
    htmlContent: `<html><head></head><body><p>name: ${name}, email: ${email}, phone: ${phone}</p>${message}</p></body></html>`,
  };

  try {
    // Send the POST request
    const response = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    });

    if (response.status === 200 || response.status === 201) {
      console.log("Email sent successfully. Status code: " + response.status);
      return true;
    } else {
      const responseBody = await response.text();
      console.log("Response body: " + responseBody);
      return false;
    }
  } catch (error) {
    console.error("Error sending the email:", error);
    return false;
  }
}
