import React from "react";
import MagentButton from "../elements/MagnetButton";

const FeaturedContentFull = () => {
  return (
    <div
      className="relative overflow-hidden bg-cover bg-no-repeat bg-[50%] h-[500px]"
      style={{ backgroundImage: `url(/images/line-piles-spices.jpg)` }}
    >
      <div className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsla(0,0%,0%,0.55)] bg-fixed">
        <div className="flex h-full items-center justify-center">
          <div className="px-6 text-center text-white md:px-12">
            <h2 className="mb-12 text-5xl font-bold leading-tight tracking-tight text-secondary-900">QUESTIONS?</h2>

            <div className="max-w-[580px]">
              <p className="text-xl">
                Feel free to contact us for any inquiries or assistance you may need, including information about our products and samples. <br />
                We're here to help!
              </p>
            </div>

            <br />

            <div className="flex flex-col items-center">
              <MagentButton title={"Ask Questions Now"} colorCode={"#fc6601"} linkAddress={"/contact"} isLarge={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedContentFull;
