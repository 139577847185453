import React from "react";
import { motion } from "framer-motion";

const WordCloud = () => {
  const cardVariantsScaled = {
    offscreen: {
      y: -20,
      opacity: 0,
      scale: 0.9,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 1.4,
        delay: 0.2,
      },
    },
  };

  return (
    <div
      className="pt-14 pb-20 bg-center bg-cover bg-no-repeat relative"
      style={{ backgroundImage: `url(/images/vibrant-burst-makeup-powder-dark-background.jpg)` }}
    >
      <div className="relative bg-no-repeat bg-center h-[500px]">
        <div className="flex h-full justify-center items-end relative z-30">
          <div className="px-6 text-center text-white md:px-12">
            <motion.button
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
              variants={cardVariantsScaled}
              whileHover={{ scale: 1.05 }}
              className="relative rounded-2xl backdrop-blur-[10px] border-[0.5px] border-primary-300 px-8 py-4 text-lg font-medium leading-normal text-neutral-50  hover:text-secondary-900 drop-shadow-lg"
              data-te-ripple-init
              data-te-ripple-color="light"
              onClick={() => (window.location.href = "/contact")}
            >
              <div className="absolute -top-3 -left-3">
                {/* Circular background */}
                <div className="w-8 h-8 bg-primary-900 p-2 rounded-full absolute drop-shadow-sm"></div>

                {/* SVG */}
                <svg
                  className="absolute w-6 h-6 left-1 top-1 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm0 16a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm1-5.034V12a1 1 0 0 1-2 0v-1.418a1 1 0 0 1 1.038-.999 1.436 1.436 0 0 0 1.488-1.441 1.501 1.501 0 1 0-3-.116.986.986 0 0 1-1.037.961 1 1 0 0 1-.96-1.037A3.5 3.5 0 1 1 11 11.466Z" />
                </svg>
              </div>
              <div className="flex flex-col max-w-[420px]">
                <span className="text-primary-600 text-3xl">Questions?</span>
                <span className="text-primary-600 text-xs">
                  Feel free to contact us for any inquiries or assistance you may need, including information about our products and samples. We're
                  here to help!
                </span>
                <span className="uppercase text-sm mt-3">Please contact us.</span>
              </div>
            </motion.button>
          </div>
        </div>

        <div
          className="absolute w-full h-full top-0 bg-no-repeat bg-center z-10 opacity-70"
          style={{ backgroundImage: `url(/images/wordcloud.svg)` }}
        ></div>
      </div>

      <div className="bg-primary-100 bg-opacity-65 absolute w-full h-full top-0 left-0"></div>
    </div>
  );
};

export default WordCloud;
