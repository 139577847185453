// Header.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const Header = ({ showLogo }) => {
  const [showHeader, setShowHeader] = useState(false);
  const [highlightNav, setHighlightNav] = useState(false);

  const currentPath = window.location.pathname;

  // Check if the current path is the home page
  const isHomePage = currentPath === "/";

  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  // ========================================================================  //
  // 🟩 Effects

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowHeader(true);
    }, 300);

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const halfInnerHeight = window.innerHeight / 2;

      if (scrollPosition >= halfInnerHeight) {
        setHighlightNav(true);
      } else {
        setHighlightNav(false);
      }
    };

    // Attach the event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      clearTimeout(timeout);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {}, []);

  // ======================================================================== //
  // 🟨 Functions

  return (
    <header className="h-20 fixed py-6 flex w-full flex-wrap items-center justify-between z-50 pb-10">
      {/* Show nav background when scrolls down */}
      <AnimatePresence>
        {highlightNav && (
          <motion.div
            key="animatedDiv"
            initial={{ opacity: 0 }} // scale: 0
            animate={{ opacity: 1 }} // scale: 1
            exit={{ opacity: 0 }} // scale: 0
            className="bg-primary-100 bg-opacity-90 w-full h-full absolute backdrop-blur-[30px] rounded-b-3xl"
          ></motion.div>
        )}
      </AnimatePresence>
      {showHeader && (
        <motion.div
          initial={{ opacity: 0, y: "-100%" }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: "20%" }}
          transition={{ duration: 0.5 }}
          className={`flex justify-center w-full `}
        >
          <nav className="space-x-10">
            {currentPath !== "/" && (
              <Link
                to="/"
                onClick={handleClick}
                className={`transition-all duration-300 ${currentPath === "/" ? "text-primary-900" : "text-white"} hover:text-primary-900`}
              >
                HOME
              </Link>
            )}
            <Link
              to="/about"
              className={` transition-all duration-300 ${currentPath === "/about" ? "text-primary-900" : "text-white"} hover:text-primary-900`}
            >
              ABOUT
            </Link>
            <Link
              to="/services"
              className={`transition-all duration-300 ${currentPath === "/services" ? "text-primary-900" : "text-white"} hover:text-primary-900`}
            >
              SERVICES
            </Link>
            <Link
              to="/contact"
              className={`transition-all duration-300 ${currentPath === "/contact" ? "text-primary-900" : "text-white"} hover:text-primary-900`}
            >
              CONTACT
            </Link>
          </nav>

          {showLogo || (highlightNav && isHomePage) ? (
            <div className="absolute left-6 font-ysabeau text-xl max-[600px]:hidden">
              <motion.button whileHover={{ scale: 1.05 }} transition={{ type: "spring", stiffness: 400, damping: 10 }} className="cursor-pointer">
                <a href="/" className="flex">
                  <img src="images/logo.png" alt="MonNutra" style={{ width: "200px" }} className="mb-24" />
                </a>
              </motion.button>
            </div>
          ) : (
            !isHomePage && (
              <div className="absolute left-6 font-ysabeau">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  className="cursor-pointer flex -mt-2.5 pt-[10px] pb-[10px] text-sm font-medium uppercase leading-normal hover:bg-opacity-10 "
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  onClick={() => (window.location.href = "/")}
                >
                  <img src="images/logo.png" alt="MonNutra" style={{ width: "200px" }} className="" />
                </motion.button>
              </div>
            )
          )}

          <div className="absolute right-6 font-ysabeau text-xl max-[600px]:hidden">
            <motion.button
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
              className="cursor-pointer flex -mt-2.5 rounded-lg backdrop-blur-[10px] border-2 border-neutral-50 px-[36px] pt-[10px] pb-[10px] text-sm font-medium uppercase leading-normal text-neutral-50 hover:border-neutral-100 hover:bg-neutral-100 hover:bg-opacity-10 hover:text-neutral-100"
              data-te-ripple-init
              data-te-ripple-color="light"
              onClick={() => (window.location.href = "tel:(976) 7609-6032")}
            >
              <div className="flex flex-row items-center">
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                  className="mx-auto h-4 w-4 mr-2 mt-[2px] text-primary-400"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m16.344 12.168-1.4-1.4a1.98 1.98 0 0 0-2.8 0l-.7.7a1.98 1.98 0 0 1-2.8 0l-2.1-2.1a1.98 1.98 0 0 1 0-2.8l.7-.7a1.981 1.981 0 0 0 0-2.8l-1.4-1.4a1.828 1.828 0 0 0-2.8 0C-.638 5.323 1.1 9.542 4.78 13.22c3.68 3.678 7.9 5.418 11.564 1.752a1.828 1.828 0 0 0 0-2.804Z"
                  />
                </svg>
                720-666-6789
              </div>
            </motion.button>
          </div>
        </motion.div>
      )}
    </header>
  );
};

export default Header;
