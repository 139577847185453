import React from "react";
import { motion } from "framer-motion";

const CallToActionTitle = () => {
  const cardVariantsScaled = {
    offscreen: {
      y: -20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 1.4,
        delay: 0.2,
      },
    },
  };

  return (
    <div className="container my-24 mx-auto md:px-6">
      <section className="mb-32 text-center">
        <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }} variants={cardVariantsScaled}>
          <div className="px-6 py-12 md:px-12">
            <h2 className="my-12 text-5xl font-bold tracking-tight max-[600px]:text-4xl">
              If we don't have what you are looking for,
              <br />
              <span className="text-secondary-900">we won't stop until we find what you need.</span>
            </h2>
          </div>
        </motion.div>
      </section>
    </div>
  );
};

export default CallToActionTitle;
