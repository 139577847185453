import { motion } from "framer-motion";
import React, { useEffect } from "react";

const MagentButton = ({ title, colorCode, linkAddress, isLarge }) => {
  const cardVariantsScaled = {
    offscreen: {
      y: -20,
      opacity: 0,
      scale: 0.9,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 1.4,
        delay: 0.2,
      },
    },
  };

  // ========================================================================  //
  // 🟩 Effects

  useEffect(() => {
    //
    // Magenet Button
    //
    const mapRange = (inputLower, inputUpper, outputLower, outputUpper) => {
      const INPUT_RANGE = inputUpper - inputLower;
      const OUTPUT_RANGE = outputUpper - outputLower;
      return (value) => outputLower + (((value - inputLower) / INPUT_RANGE) * OUTPUT_RANGE || 0);
    };

    const list = document.querySelector("ul");
    const items = document.querySelectorAll("li");

    const updateMagnet = (event) => {
      const item = event.currentTarget;
      const xRange = item.magnetMapper.x(item.dataset.centerX - event.x);
      const yRange = item.magnetMapper.y(item.dataset.centerY - event.y);
      item.style.setProperty("--magnet-x", xRange);
      item.style.setProperty("--magnet-y", yRange);
      // Update list magnet if being used
      list.style.setProperty("--list-x", xRange);
      list.style.setProperty("--list-y", yRange);
    };

    const disableMagnet = (event) => {
      event.target.style.setProperty("--magnet-x", 0);
      event.target.style.setProperty("--magnet-y", 0);
      // Update list magnet if being used
      list.style.setProperty("--list-x", 0);
      list.style.setProperty("--list-y", 0);
      event.target.removeEventListener("pointermove", updateMagnet);
      event.target.removeEventListener("pointerleave", disableMagnet);
    };

    const activateMagnet = (event) => {
      const item = event.target;
      const bounds = item.getBoundingClientRect();

      // Cache the center position on enter
      item.dataset.centerX = bounds.x + item.offsetWidth * 0.5;
      item.dataset.centerY = bounds.y + item.offsetHeight * 0.5;

      // Cache the mapper agains the element
      if (!item.magnetMapper) {
        item.magnetMapper = {
          x: mapRange(item.offsetWidth * -0.5, item.offsetWidth * 0.5, 1, -1),
          y: mapRange(item.offsetHeight * -0.5, item.offsetHeight * 0.5, 1, -1),
        };
      }
      // Optionally update the magnet for the list
      list.style.setProperty("--at", bounds.top);
      list.style.setProperty("--aw", bounds.right - bounds.left);
      list.style.setProperty("--ah", bounds.bottom - bounds.top);
      list.style.setProperty("--al", bounds.left);

      if (event.type === "pointerenter") {
        item.addEventListener("pointermove", updateMagnet);
        item.addEventListener("pointerleave", disableMagnet);
      }
    };

    items.forEach((item) => {
      item.addEventListener("pointerenter", activateMagnet);
      item.addEventListener("focus", activateMagnet);
    });
  }, []);

  return (
    <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }} variants={cardVariantsScaled}>
      <nav className="magnetButtonWrapper">
        <ul>
          <li>
            <a href={linkAddress} style={{ backgroundColor: `${colorCode}` }}>
              {isLarge ? (
                <span className="text-primary-500 font-roboto font-bold uppercase text-2xl py-2 px-8">{title}</span>
              ) : (
                <span className="text-primary-100 font-roboto font-bold uppercase">{title}</span>
              )}
            </a>
          </li>
        </ul>
      </nav>
    </motion.div>
  );
};

export default MagentButton;
