import React from "react";

const SectionTitle = ({ title, isBright }) => {
  const textColorClass = isBright ? "text-primary-200" : "text-primary-100";
  const lineColorClass = isBright ? "bg-primary-900" : "bg-secondary-900";

  return (
    <div className="flex flex-col items-center justify-center mb-20">
      <h2 className={`${textColorClass} text-3xl font-bold text-center`}>{title}</h2>
      <div className={`${lineColorClass} w-100 h-1.5 my-4 w-full max-w-[100px]`}></div>
    </div>
  );
};

export default SectionTitle;
