import { motion, useAnimation } from "framer-motion";
import React from "react";

const Footer = () => {
  const sectionControls = useAnimation();

  return (
    <div className="container min-w-full">
      <div className="relative overflow-hidden bg-cover bg-no-repeat bg-[50%] min-h-[200px]">
        <motion.section initial="hidden" animate={sectionControls} transition={{ duration: 0.2 }}>
          <div className="flex flex-col justify-center items-center py-12">
            <div className="grid gap-x-6 grid-cols-2 lg:grid-cols-4">
              <div className="mx-auto mb-12 text-center lg:mb-0">
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                  className="mx-auto mb-6 h-8 w-8 text-primary-400"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 2-8.4 7.05a1 1 0 0 1-1.2 0L1 2m18 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1m18 0v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2"
                  />
                </svg>
                <motion.button whileHover={{ scale: 1.05 }} transition={{ type: "spring", stiffness: 400, damping: 10 }} className="cursor-pointer">
                  <a href="mailto:monnutra@gmail.com" target="_blank" rel="noreferrer">
                    <h6 className="font-medium text-primary-100 opacity-85">monnutra@gmail.com</h6>
                  </a>
                </motion.button>
              </div>

              <div className="mx-auto mb-12 text-center lg:mb-0">
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                  className="mx-auto mb-6 h-8 w-8 text-primary-400"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m16.344 12.168-1.4-1.4a1.98 1.98 0 0 0-2.8 0l-.7.7a1.98 1.98 0 0 1-2.8 0l-2.1-2.1a1.98 1.98 0 0 1 0-2.8l.7-.7a1.981 1.981 0 0 0 0-2.8l-1.4-1.4a1.828 1.828 0 0 0-2.8 0C-.638 5.323 1.1 9.542 4.78 13.22c3.68 3.678 7.9 5.418 11.564 1.752a1.828 1.828 0 0 0 0-2.804Z"
                  />
                </svg>

                <motion.button whileHover={{ scale: 1.05 }} transition={{ type: "spring", stiffness: 400, damping: 10 }} className="cursor-pointer">
                  <a href="tel:(976) 7609-6032">
                    <h6 className="font-medium text-primary-100 opacity-85">720-666-6789</h6>
                  </a>
                </motion.button>
              </div>

              <div className="mx-auto mb-12 text-center lg:mb-0">
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 20"
                  className="mx-auto mb-6 h-8 w-8 text-primary-400"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 13A6 6 0 1 0 7 1a6 6 0 0 0 0 12Zm0 0v6M4.5 7A2.5 2.5 0 0 1 7 4.5"
                  />
                </svg>
                <h6 className="font-medium text-primary-100 opacity-85">Denver, CO, 80222</h6>
              </div>

              <div className="mx-auto mb-12 text-center lg:mb-0">
                <a href="https://www.facebook.com/profile.php?id=61554372388320" target="_blank" rel="noreferrer">
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 8 19"
                    className="mx-auto mb-6 h-8 w-8 text-primary-400"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <motion.button whileHover={{ scale: 1.05 }} transition={{ type: "spring", stiffness: 400, damping: 10 }} className="cursor-pointer">
                    <h6 className="font-medium text-primary-100 opacity-85">Follow Us</h6>
                  </motion.button>
                </a>
              </div>
            </div>

            <br />

            <div className="mt-4">
              <div className="bg-primary-100 opacity-40 w-100 h-[0.8px] w-full mb-2"></div>
              <p className="text-sm text-primary-100 opacity-70">© 2024 MonNutra All Rights Reserved.</p>
            </div>
          </div>
        </motion.section>
      </div>
    </div>
  );
};

export default Footer;
