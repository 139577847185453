import { motion } from "framer-motion";
import React from "react";

const PageHeader = ({ title, imageName, titleSmaller }) => {
  const titleSize = titleSmaller ? "text-7xl" : "text-8xl";
  const cardVariantsScaled = {
    offscreen: {
      y: -20,
      opacity: 0,
      scale: 0.9,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 1.4,
        delay: 0.4,
      },
    },
  };

  return (
    <motion.section
      className="relative max-h-[480px] flex justify-center items-center bg-center bg-cover"
      style={{ backgroundImage: `url(/images/${imageName}.jpg)`, backgroundSize: "cover", height: "100vh" }}
    >
      <div className="absolute inset-0 flex flex-col justify-center items-center z-20">
        <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }} variants={cardVariantsScaled}>
          <div className="flex flex-col justify-center items-center transition-all w-full">
            <h1
              className={`text-center text-primary-600 leading-normal ${titleSize} max-md:text-6xl mx-6 relative max-[600px]:text-5xl flex flex-col uppercase`}
            >
              {title}
            </h1>

            <div className="w-100 h-1.5 w-full max-w-[100px] bg-primary-900"></div>
          </div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default PageHeader;
