import React from "react";
import { motion } from "framer-motion";
import SectionTitle from "../elements/SectionTitle";

const OurServices = () => {
  const cardVariantsScaled1 = {
    offscreen: {
      y: -20,
      opacity: 0,
      scale: 0.9,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 1.4,
        delay: 0.2,
      },
    },
  };

  const cardVariantsScaled2 = {
    offscreen: {
      y: -20,
      opacity: 0,
      scale: 0.9,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 1.4,
        delay: 0.4,
      },
    },
  };

  const cardVariantsScaled3 = {
    offscreen: {
      y: -20,
      opacity: 0,
      scale: 0.9,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 1.4,
        delay: 0.6,
      },
    },
  };

  return (
    <div className="container max-w-screen-xl my-24 mx-auto md:px-6 text-center">
      <section className="mb-32 text-center max-[600px]:mb-0">
        <SectionTitle title="Our Services" isBright={false} />

        <div className="grid lg:grid-cols-3 lg:gap-x-12">
          <div className="mb-12 lg:mb-0">
            <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }} variants={cardVariantsScaled1}>
              <div className="block rounded-2xl">
                <img className="rounded-2xl inline-flex" src="images/featured-1.jpg" alt="" />
                <div className="p-6">
                  <h5 className="mb-4 text-2xl font-medium text-primary-900">Procurement</h5>
                  <p className="text-primary-100 font-roboto">
                    We work closely with a vast network of over 100 trusted suppliers worldwide, ensuring that we have a wide range of ingredients and
                    products available. Additionally, we offer logistics services to facilitate a seamless procurement process.
                  </p>
                </div>
              </div>
            </motion.div>
          </div>

          <div className="mb-12 lg:mb-0">
            <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }} variants={cardVariantsScaled2}>
              <div className="block rounded-2xl">
                <img className="rounded-2xl inline-flex" src="images/featured-2.jpg" alt="" />
                <div className="p-6">
                  <h5 className="mb-4 text-2xl font-medium text-primary-900">Quality Assurance</h5>
                  <p className="text-primary-100 font-roboto">
                    We prioritize the quality of the ingredients and products we source. Through rigorous evaluation and selection processes, we
                    ensure that our offerings meet stringent quality standards. As part of our commitment to quality, we can also provide lab test
                    services to verify the purity, potency, and safety of the products, giving you complete confidence in the integrity of your
                    supplies.
                  </p>
                </div>
              </div>
            </motion.div>
          </div>

          <div className="mb-12 lg:mb-0">
            <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }} variants={cardVariantsScaled3}>
              <div className="block rounded-2xl">
                <img className="rounded-2xl inline-flex" src="images/featured-3.jpg" alt="" />
                <div className="p-6">
                  <h5 className="mb-4 text-2xl font-medium text-primary-900">Custom Blending</h5>
                  <p className="text-primary-100 font-roboto">
                    Our expertise extends beyond procurement. We offer custom blending services, allowing you to create unique formulations tailored
                    to your specific needs. With our knowledge and capabilities, we can assist in developing customized blends that align with your
                    product vision and requirements.
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurServices;
