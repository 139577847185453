import React from "react";
import { motion } from "framer-motion";
import MagentButton from "../elements/MagnetButton";

const SectionHome = ({ randomBackgroundImage }) => {
  const cardVariantsScaled = {
    offscreen: {
      y: -20,
      opacity: 0,
      scale: 0.9,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 1.4,
        delay: 0.2,
      },
    },
  };

  const cardVariantsFadeIn = {
    offscreen: {
      y: -20,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 1.4,
        delay: 0.2,
      },
    },
  };

  return (
    <motion.section
      className="relative h-screen flex justify-center items-center bg-center bg-cover"
      style={{ backgroundImage: `url(${randomBackgroundImage})`, key: randomBackgroundImage, backgroundSize: "cover", height: "100vh" }}
    >
      <div className="absolute inset-0 flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center transition-all w-full text-center items-center mb-12">
          <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }} variants={cardVariantsScaled}>
            <img src="images/logo.png" alt="MonNutra" style={{ width: "300px" }} className="mb-24" />
          </motion.div>

          <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }} variants={cardVariantsFadeIn}>
            <h1 className="text-center text-primary-600 leading-normal text-8xl max-md:text-6xl mx-6 relative max-[600px]:text-5xl flex flex-col">
              <span className="text-center text-primary-600 leading-normal text-4xl max-md:text-3xl mx-6 relative opacity-75 max-[600px]:text-xl max-[800px]:top-0 top-8">
                We offer the
              </span>
              Best Ingredients
            </h1>
            <p className="text-center text-primary-300 leading-normal text-xl max-md:text-xl mx-6 font-thin max-[600px]:text-lg">
              From A-Z, just name what you are looking for and we will do all the work for you.
            </p>
          </motion.div>

          <br />
        </div>

        <MagentButton title={"Get a Quote"} colorCode={"#fc6601"} linkAddress={"/contact"} isLarge={false} />
      </div>
    </motion.section>
  );
};

export default SectionHome;
